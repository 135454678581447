import React from 'react'
import view from "./contacthours.module.scss"

function ContactHours({ data }) {
    const dealer = data;

    const mapHours = (arr) => {
        let salesHours = arr.salesHours.map(entry => {
            return (
                <div key={entry.text} className={view["eachHour"]}><span className={view["day"]}>{entry.day}: </span><br/>{entry.time}</div>
            )
        })
        let serviceHours = arr.serviceHours.map(entry => {
            return (
                <div key={entry.text} className={view["eachHour"]}><span className={view["day"]}>{entry.day}: </span><br/>{entry.time}</div>
            )
        })
        let financeHours = arr.financeHours.map(entry => {
            return (
                <div key={entry.text} className={view["eachHour"]}><span className={view["day"]}>{entry.day}: </span><br/>{entry.time}</div>
            )
        })
        let partsHours = arr.partsHours.map(entry => {
            return (
                <div key={entry.text} className={view["eachHour"]}><span className={view["day"]}>{entry.day}: </span><br/>{entry.time}</div>
            )
        })
        let collisionHours = arr.collisionHours.map(entry => {
            return (
                <div key={entry.text} className={view["eachHour"]}><span className={view["day"]}>{entry.day}: </span><br/>{entry.time}</div>
            )
        })
        

        return(
                <div className={view["hours-cont"]}>
                  { salesHours.length > 0 &&
                    <div class={view["hours-seg"]}>
                        <h3 className={view["hours-header"]}>Sales Hours</h3>
                        {salesHours}
                    </div>
                  } { serviceHours.length > 0 &&
                    <div class={view["hours-seg"]}>
                        <h3 className={view["hours-header"]}>Service Department</h3>
                        {serviceHours}
                    </div>
                } { financeHours.length > 0 &&
                    <div class={view["hours-seg"]}>
                        <h3 className={view["hours-header"]}>Financing</h3>
                        {financeHours}
                    </div>
                } { partsHours.length > 0 &&
                    <div class={view["hours-seg"]}>
                        <h3 className={view["hours-header"]}>Parts Department</h3>
                        {partsHours}
                    </div>
                } { collisionHours.length > 0 &&
                    <div class={view["hours-seg"]}>
                      <h3 className={view["hours-header"]}>Collision Department</h3>
                      {collisionHours}
                    </div>
                }
                </div>
        )
    }

    return(
        <div className={view["contact-hours"]}>
            <div className={view["contact-info-cont"]}>
                <div className={view["top-bar"]}></div>
                <div className={view["contact-info"]}>
                    {dealer.dealerAddress}
                    <p className={view["contact-phone"]}>{dealer.serviceNumber}</p>
                </div>
                {mapHours(dealer)}
            </div>
        </div>
    )

}

export default ContactHours