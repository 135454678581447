import React, { useState } from "react"
import grid from "../../scss/flexboxgrid.module.scss"
import { Link } from "gatsby";
import { Hidden } from "@material-ui/core";


function BannerImage({data}) {
const {imgSrc, imgHeight} = data

    if(imgSrc.length > 0) {
    return(
        <div className="large-page-img" style={{ overflow: "hidden", width:"100%"}}>
            <img alt="Lotus Cars Facing the Viewer" src={imgSrc} style={{maxHeight: imgHeight, objectFit:"cover", width: "100%"}}/>
        </div>
    
    )
    }
    else {
        return (<div></div>)
    }

}
export default BannerImage